var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('fragment', [_c('ul', {
    class: _vm.className
  }, _vm._l(_vm.list, function (item, index) {
    return _c('li', {
      key: index,
      class: item.liClassName,
      style: item.dialogComponent ? 'margin-left: 0' : ''
    }, [_vm.type === 'menu' && item.depth === 0 ? _c('strong', {
      staticClass: "footer_menu_title"
    }, [_c('span', {
      staticClass: "menu_text"
    }, [_vm._v(_vm._s(item.text))])]) : _vm.type === 'sns' && item.depth === 1 ? _c('a', {
      staticClass: "btn_sns",
      class: item.linkClassName,
      attrs: {
        "href": item.link,
        "target": "_blank",
        "title": "새창열림"
      }
    }, [_c('span', {
      staticClass: "blind"
    }, [_vm._v(_vm._s(item.text))])]) : _vm.type === 'sns' && item.depth === 0 ? _c('strong', {
      staticClass: "footer_sns_category"
    }, [_vm._v(_vm._s(item.text))]) : !item.name && item.link ? _c('a', {
      class: item.linkClassName,
      attrs: {
        "href": item.link,
        "target": "_blank"
      }
    }, [_vm._v(" " + _vm._s(item.text) + " ")]) : item.dialogComponent ? _c('a', {
      staticClass: "dialog",
      class: item.linkClassName,
      attrs: {
        "href": "#none"
      },
      on: {
        "click": function click($event) {
          $event.preventDefault();
          return _vm.openDialogHandler(item);
        }
      }
    }, [_vm._v(" " + _vm._s(item.text) + " ")]) : _c('router-link', {
      class: item.linkClassName,
      attrs: {
        "to": {
          name: item.name
        }
      }
    }, [_vm._v(_vm._s(item.text))]), item.children ? _c('FooterList', {
      attrs: {
        "type": _vm.type,
        "class-name": _vm.depthUlClassName,
        "list": item.children
      }
    }) : _vm._e()], 1);
  }), 0), _c(_vm.showDialog, {
    tag: "component",
    attrs: {
      "value": true
    },
    on: {
      "close": _vm.closeDialogHandler
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }