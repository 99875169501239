var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('FooterDialogWrapper', _vm._g({
    attrs: {
      "value": _vm.value,
      "title": "위즈덤하우스 이용약관 및 운영규칙"
    }
  }, _vm.$listeners), [_c('div', {
    staticClass: "layer_content"
  }, [_c('section', {
    staticClass: "layer_content_inner layer_util_terms"
  }, [_c('h2', {
    staticClass: "terms_title"
  }, [_vm._v("제 1 조 (목적)")]), _c('div', {
    staticClass: "terms_paragraph_box"
  }, [_c('p', [_vm._v(" 이 약관은 위즈덤하우스(주)(이하 ‘회사’라 합니다)가 제공하는 위즈덤하우스의 서비스의 이용과 관련하여 회사와 회원과의 권리, 의무 및 책임사항 기타 필요한 사항을 규정함을 목적으로 합니다. ")])]), _c('h2', {
    staticClass: "terms_title"
  }, [_vm._v("제 2 조 (약관의 게시와 개정)")]), _c('div', {
    staticClass: "terms_paragraph_box"
  }, [_c('p', [_vm._v(" 위즈덤하우스 및 위즈덤하우스 관련 제반 서비스(모바일 웹/앱 포함)의 회원관리, 서비스 개발·제공 및 향상, 안전한 인터넷 이용환경 구축 등 아래의 목적으로만 개인정보를 이용합니다. ")]), _c('ul', {
    staticClass: "txt_list type_num3"
  }, [_c('li', [_c('span', {
    staticClass: "txt_num"
  }, [_vm._v("①")]), _vm._v("회사는 이 약관을 회원이 그 전부를 확인할 수 있도록 합니다.")]), _c('li', [_c('span', {
    staticClass: "txt_num"
  }, [_vm._v("②")]), _vm._v("회사는 ‘콘텐츠산업진흥법’, ‘전자상거래등에서의소비자보호에관한법률’, ‘약관의규제에관한법률’ 등 관련 법령을 위배하지 않는 범위에서 이 약관을 개정할 수 있습니다. ")]), _c('li', [_c('span', {
    staticClass: "txt_num"
  }, [_vm._v("③")]), _vm._v("회사가 약관을 개정할 경우에는 적용일자 및 개정사유를 명시하여 서비스 초기 화면에 그 개정약관의 적용일자 7일 전부터 적용일자 전일까지 공지합니다. 다만, 회원에게 불리한 약관의 개정의 경우에는 30일 전부터 적용일자 전일까지 공지하며, 공지 외에 일정기간 서비스 내 전자우편, 전자쪽지, 로그인시 동의창 등의 전자적 수단을 통해 따로 명확히 통지하도록 합니다. ")]), _c('li', [_c('span', {
    staticClass: "txt_num"
  }, [_vm._v("④")]), _vm._v("회사가 전항에 따라 개정약관을 공지 또는 통지하면서 회원에게 전항의 공지기간 내에 의사표시를 하지 않으면 의사표시가 표명된 것으로 본다는 뜻을 명확하게 공지 또는 통지하였음에도 회원이 명시적으로 거부의 의사표시를 하지 아니한 경우 회원이 개정약관에 동의한 것으로 봅니다. ")]), _c('li', [_c('span', {
    staticClass: "txt_num"
  }, [_vm._v("⑤")]), _vm._v("회원이 개정약관의 적용에 동의하지 않는 경우 회사는 개정 약관의 내용을 적용할 수 없으며, 이 경우 회원은 이용계약을 해지할 수 있습니다. 다만, 기존 약관을 적용할 수 없는 특별한 사정이 있는 경우에는 회사는 이용계약을 해지할 수 있습니다. ")]), _c('li', [_c('span', {
    staticClass: "txt_num"
  }, [_vm._v("⑥")]), _vm._v("회원은 전항에 따른 이용계약의 해지로 손해가 발생한 경우 회사에 이에 대한 배상을 청구할 수 있습니다. ")])])]), _c('h2', {
    staticClass: "terms_title"
  }, [_vm._v("제 3 조 (약관의 해석)")]), _c('div', {
    staticClass: "terms_paragraph_box"
  }, [_c('p', [_vm._v(" 이 약관에서 정하지 아니한 사항과 이 약관의 해석에 관하여는 ‘콘텐츠진흥법’, ‘전자상거래등에서의소비자보호에관한법률’, ‘약관의규제에관한법률’, 문화체육관광부장관이 정하는 ‘콘텐츠이용자보호지침’, 기타 관계법령, 또는 상관례에 따릅니다. ")])]), _c('h2', {
    staticClass: "terms_title"
  }, [_vm._v("제4조 (회원에 대한 통지)")]), _c('div', {
    staticClass: "terms_paragraph_box"
  }, [_c('ul', {
    staticClass: "txt_list type_num3"
  }, [_c('li', [_c('span', {
    staticClass: "txt_num"
  }, [_vm._v("①")]), _vm._v("회사가 회원에 대한 통지를 하는 경우 이 약관에 별도 규정이 없는 한 서비스 내 전자우편주소, 전자쪽지 등으로 할 수 있습니다. ")]), _c('li', [_c('span', {
    staticClass: "txt_num"
  }, [_vm._v("②")]), _vm._v("회사는 회원 전체에 대한 통지의 경우 7일 이상 회사의 게시판에 게시함으로써 제1항의 통지에 갈음할 수 있습니다. 다만, 회원 본인의 거래와 관련하여 중대한 영향을 미치는 사항에 대하여는 제1항의 통지를 합니다. ")])])]), _c('h2', {
    staticClass: "terms_title"
  }, [_vm._v("제 5 조 (유료서비스 이용계약의 성립 등)")]), _c('div', {
    staticClass: "terms_paragraph_box"
  }, [_c('ul', {
    staticClass: "txt_list type_num3"
  }, [_c('li', [_c('span', {
    staticClass: "txt_num"
  }, [_vm._v("①")]), _vm._v("회원은 회사가 제공하는 다음 또는 이와 유사한 절차에 의하여 이용신청을 합니다. 회사는 계약 체결 전에 각 호의 사항에 관하여 회원이 정확하게 이해하고 실수 또는 착오 없이 거래할 수 있도록 정보를 제공합니다. "), _c('ul', {
    staticClass: "txt_list type_num1"
  }, [_c('li', [_c('span', {
    staticClass: "txt_num"
  }, [_vm._v("1)")]), _vm._v("유료서비스의 확인 및 선택")]), _c('li', [_c('span', {
    staticClass: "txt_num"
  }, [_vm._v("2)")]), _vm._v("결제방법의 선택 및 결제정보의 입력")]), _c('li', [_c('span', {
    staticClass: "txt_num"
  }, [_vm._v("3)")]), _vm._v("유료서비스의 이용신청에 관한 확인 또는 회사의 확인에 대한 동의")])])]), _c('li', [_c('span', {
    staticClass: "txt_num"
  }, [_vm._v("②")]), _vm._v("회사는 회원의 이용신청이 다음 각 호에 해당하는 경우에는 승낙하지 않거나 승낙을 유보할 수 있습니다. "), _c('ul', {
    staticClass: "txt_list type_num1"
  }, [_c('li', [_c('span', {
    staticClass: "txt_num"
  }, [_vm._v("1)")]), _vm._v("실명이 아니거나 타인의 명의를 이용한 경우")]), _c('li', [_c('span', {
    staticClass: "txt_num"
  }, [_vm._v("2)")]), _vm._v("허위의 정보를 기재하거나, 회사가 제시하는 내용을 기재하지 않은 경우 ")]), _c('li', [_c('span', {
    staticClass: "txt_num"
  }, [_vm._v("3)")]), _vm._v("미성년자가 ‘청소년보호법’ 등 관련 법령에 의해서 이용이 금지되는 서비스를 이용하고자 하는 경우 ")]), _c('li', [_c('span', {
    staticClass: "txt_num"
  }, [_vm._v("4)")]), _vm._v("서비스 관련 설비의 여유가 없거나, 기술상 또는 업무상 문제가 있는 경우 ")])])]), _c('li', [_c('span', {
    staticClass: "txt_num"
  }, [_vm._v("③")]), _vm._v("이용계약의 성립시기는 ‘신청완료’ 또는 ‘결제완료’를 신청절차 상에서 표시한 시점으로 합니다. ")]), _c('li', [_c('span', {
    staticClass: "txt_num"
  }, [_vm._v("④")]), _vm._v("회원이 유료서비스를 이용하기 위해서는 이 약관에 동의 후 각 서비스에 따른 이용조건에 따라 이용요금을 지급하여야 합니다. ")])])]), _c('h2', {
    staticClass: "terms_title"
  }, [_vm._v("제 6 조 (유료서비스의 중단 및 변경)")]), _c('div', {
    staticClass: "terms_paragraph_box"
  }, [_c('ul', {
    staticClass: "txt_list type_num3"
  }, [_c('li', [_c('span', {
    staticClass: "txt_num"
  }, [_vm._v("①")]), _vm._v("회사는 사업 종목의 전환, 사업의 포기, 업체 간의 통합 등의 이유로 유료서비스를 제공할 수 없게 되는 경우에는 회사는 이 약관에서 정한 방법으로 회원에게 통지하고 관련 법령 규정에서 정한 바에 따라 회원에게 보상합니다. ")]), _c('li', [_c('span', {
    staticClass: "txt_num"
  }, [_vm._v("②")]), _vm._v("회사는 상당한 이유가 있는 경우에 운영상, 기술상의 필요에 따라 제공하고 있는 전부 또는 일부의 유료서비스를 변경할 수 있고, 변경 전 해당 서비스 초기 화면에 관련 사항을 게시합니다. 다만, 변경된 내용이 중대하거나 회원에게 불리한 경우에는 이 약관에서 정한 방법으로 통지하고, 중대하거나 회원에게 불리한 변경 내용에 동의하지 않는 회원은 제8조에서 정한 바에 따라 유료서비스 이용계약을 해지할 수 있습니다. ")])])]), _c('h2', {
    staticClass: "terms_title"
  }, [_vm._v("제 7 조 (회원의 청약철회 및 계약해지)")]), _c('div', {
    staticClass: "terms_paragraph_box"
  }, [_c('ul', {
    staticClass: "txt_list type_num3"
  }, [_c('li', [_c('span', {
    staticClass: "txt_num"
  }, [_vm._v("①")]), _vm._v("회사와 유료서비스 이용계약을 체결한 회원은 관련 법령에서 정한 바에 따라 일정 기간 내에 청약을 철회할 수 있습니다. ")]), _c('li', [_c('span', {
    staticClass: "txt_num"
  }, [_vm._v("②")]), _vm._v("회원은 다음 각 호의 사유가 있을 때 유료서비스 이용계약을 해지 또는 해제할 수 있습니다. "), _c('ul', {
    staticClass: "txt_list type_num1"
  }, [_c('li', [_c('span', {
    staticClass: "txt_num"
  }, [_vm._v("1)")]), _vm._v("유료서비스의 하자를 회사가 보완, 수정할 수 없는 경우 유료서비스를 공급받은 날로부터 7일 이내 ")]), _c('li', [_c('span', {
    staticClass: "txt_num"
  }, [_vm._v("2)")]), _vm._v("유료서비스 회원이 이 약관의 개정 또는 제7조 제2항 단서에 따른 서비스 변경에 동의하지 않아 회원탈퇴 또는 유료 서비스 이용계약을 해지하는 경우 ")])])]), _c('li', [_c('span', {
    staticClass: "txt_num"
  }, [_vm._v("③")]), _vm._v("회원이 전항에 따라 유료서비스 이용계약을 해지 또는 해제하는 경우 회사는 회원으로부터 지급받은 대금을 관련 법령 규정, 이 약관에서 정한 바에 따라 회원에게 환불합니다. ")]), _c('li', [_c('span', {
    staticClass: "txt_num"
  }, [_vm._v("④")]), _vm._v("회사, 유료서비스의 대금을 지급 받은 자 또는 회원과 유료서비스 이용계약을 체결한 자가 동일인이 아닌 경우에 각자는 청약철회 등과 관련한 의무의 이행에 있어서 연대하여 책임을 집니다 ")])])]), _c('h2', {
    staticClass: "terms_title"
  }, [_vm._v("제 8 조 (회사의 계약해제, 해지 및 이용제한)")]), _c('div', {
    staticClass: "terms_paragraph_box"
  }, [_c('ul', {
    staticClass: "txt_list type_num3"
  }, [_c('li', [_c('span', {
    staticClass: "txt_num"
  }, [_vm._v("①")]), _vm._v("회사는 회원이 위즈덤하우스 이용약관에서 정한 금지행위를 하였을 경우 해당 조항에 따라 사전통지 없이 계약을 해제, 해지하거나 또는 기간을 정하여 서비스이용을 제한할 수 있습니다. ")]), _c('li', [_c('span', {
    staticClass: "txt_num"
  }, [_vm._v("②")]), _vm._v("제1항의 사유로 환불이 필요할 경우 회사는 회원이 유료서비스 이용으로부터 얻은 이익에 해당하는 금액을 공제하고 다음 각호에 따라 환불합니다. "), _c('ul', {
    staticClass: "txt_list type_num1"
  }, [_c('li', [_c('span', {
    staticClass: "txt_num"
  }, [_vm._v("1)")]), _vm._v("회사는 회원에게 계약해제, 해지의 의사표시에 대하여 회신한 날로부터 영업일 기준 1일 이내에 대금의 결제와 동일한 방법으로 이를 환불하여야 하고 동일한 방법으로 환불이 불가능할 때에는 이를 사전에 고지하여야 합니다. 다만, 수납확인이 필요한 경우에는 수납확인일로부터 영업일 기준 7일 이내에 이를 환불하도록 합니다. ")]), _c('li', [_c('span', {
    staticClass: "txt_num"
  }, [_vm._v("2)")]), _vm._v("회사는 위 대금을 환불함에 있어서 회원이 신용카드 또는 전자화폐 등의 결제수단으로 재화 등의 대금을 지급한 때에는 지체 없이 당해 결제수단을 제공한 사업자로 하여금 재화 등의 대금의 청구를 정지 또는 취소하도록 요청합니다. 다만, 제2항 각 호외의 본문 및 제2항 제1호 단서의 경우에는 그러하지 아니할 수 있습니다. ")])])]), _c('li', [_c('span', {
    staticClass: "txt_num"
  }, [_vm._v("③")]), _vm._v("제1항의 해제, 해지는 회사가 정한 통지방법에 따라 회원에게 그 의사를 표시한 때에 효력이 발생합니다. ")])])]), _c('h2', {
    staticClass: "terms_title"
  }, [_vm._v("제9조 (회사의 계약해제, 해지 및 이용제한)")]), _c('div', {
    staticClass: "terms_paragraph_box"
  }, [_c('ul', {
    staticClass: "txt_list type_num3"
  }, [_c('li', [_c('span', {
    staticClass: "txt_num"
  }, [_vm._v("①")]), _vm._v("사이트의 게시물과 자료의 관리 및 운영의 책임은 회사에 있습니다. 회사는 항상 불량 게시물 및 자료에 대하여 모니터링을 하여야 하며, 불량 게시물 및 자료를 발견하거나 신고를 받으면 해당 게시물 및 자료를 삭제하고 이를 등록한 회원에게 주의를 주어야 합니다. ")]), _c('li', [_c('span', {
    staticClass: "txt_num"
  }, [_vm._v("②")]), _vm._v("정보통신윤리위원회 등 공공기관의 시정요구가 있는 경우 회사는 회원의 사전 동의 없이 게시물을 삭제하거나 이동할 수 있습니다. ")]), _c('li', [_c('span', {
    staticClass: "txt_num"
  }, [_vm._v("③")]), _vm._v("불량 게시물의 판단 기준은 다음과 같습니다. "), _c('ul', {
    staticClass: "txt_list type_num1"
  }, [_c('li', [_c('span', {
    staticClass: "txt_num"
  }, [_vm._v("1)")]), _vm._v("다른 회원 또는 제 3자에게 심한 모욕을 주거나 명예를 손상시키는 내용인 경우 ")]), _c('li', [_c('span', {
    staticClass: "txt_num"
  }, [_vm._v("2)")]), _vm._v("공공질서 및 미풍양속에 위반되는 내용을 유표하거나 링크시키는 경우 ")]), _c('li', [_c('span', {
    staticClass: "txt_num"
  }, [_vm._v("3)")]), _vm._v("불법복제 또는 해킹을 조장하는 내용인 경우")]), _c('li', [_c('span', {
    staticClass: "txt_num"
  }, [_vm._v("4)")]), _vm._v("영리를 목적으로 하는 광고일 경우")]), _c('li', [_c('span', {
    staticClass: "txt_num"
  }, [_vm._v("5)")]), _vm._v("범죄와 결부된다고 객관적으로 인정되는 내용일 경우")]), _c('li', [_c('span', {
    staticClass: "txt_num"
  }, [_vm._v("6)")]), _vm._v("다른 이용자 또는 제 3자와 저작권 등 기타 권리를 침해하는 경우")]), _c('li', [_c('span', {
    staticClass: "txt_num"
  }, [_vm._v("7)")]), _vm._v("기타 관계법령에 위배된다고 판단되는 경우")])])]), _c('li', [_c('span', {
    staticClass: "txt_num"
  }, [_vm._v("④")]), _vm._v("사이트 및 회사는 게시물 등에 대하여 제3자로부터 명예훼손, 지적재산권 등의 권리 침해를 이유로 게시중단 요청을 받은 경우 이를 임시로 게시중단(전송중단)할 수 있으며, 게시중단 요청자와 게시물 등록자 간에 소송, 합의 기타 이에 준하는 관련 기관의 결정 등이 이루어져 회사에 접수된 경우 이에 따릅니다. ")])])]), _c('h2', {
    staticClass: "terms_title"
  }, [_vm._v("제10조 (게시물에 대한 저작권)")]), _c('div', {
    staticClass: "terms_paragraph_box"
  }, [_c('ul', {
    staticClass: "txt_list type_num3"
  }, [_c('li', [_c('span', {
    staticClass: "txt_num"
  }, [_vm._v("①")]), _vm._v("회원이 사이트 내에 게시한 게시물의 저작권은 게시한 회원에게 귀속됩니다. 또한 사이트는 게시자의 동의 없이 게시물을 상업적으로 이용할 수 없습니다. ")]), _c('li', [_c('span', {
    staticClass: "txt_num"
  }, [_vm._v("②")]), _vm._v("위즈덤하우스에서 게재한 게시물에 대한 저작권은 위즈덤하우스에 있으며 회원은 서비스를 이용하여 취득한 정보를 임의 가공, 판매하는 행위 등 상업적으로 사용할 수 없습니다. ")]), _c('li', [_c('span', {
    staticClass: "txt_num"
  }, [_vm._v("③")]), _vm._v("위즈덤하우스에서 연재되는 작품의 판권은 지은이와 위즈덤하우스에 있으며 무단 전재와 복제를 금합니다. ")])])]), _c('h2', {
    staticClass: "terms_title"
  }, [_vm._v("제11조 (유료서비스 하자 등에 의한 회원피해보상)")]), _c('div', {
    staticClass: "terms_paragraph_box"
  }, [_c('p', [_vm._v(" 회사는 유료서비스의 하자 등에 의한 회원의 피해보상 기준, 범위, 방법 및 절차에 관한 사항을 ‘콘텐츠이용자보호지침’에 따라 처리합니다. ")])]), _c('h2', {
    staticClass: "terms_title"
  }, [_vm._v("제12조 (책임 제한)")]), _c('div', {
    staticClass: "terms_paragraph_box"
  }, [_c('ul', {
    staticClass: "txt_list type_num3"
  }, [_c('li', [_c('span', {
    staticClass: "txt_num"
  }, [_vm._v("①")]), _vm._v("회사는 관계법령의 변경, 천재지변 또는 이에 준하는 불가항력으로 인하여 서비스를 제공할 수 없는 경우에는 서비스 제공에 관한 책임이 면제됩니다. ")]), _c('li', [_c('span', {
    staticClass: "txt_num"
  }, [_vm._v("②")]), _vm._v("회사는 본 사이트의 서비스 기반 및 타 통신업자가 제공하는 전기통신서비스의 장애로 인한 경우에는 책임이 면제되며 본 사이트의 서비스 기반과 관련되어 발생한 손해에 대해서는 회사의 이용약관에 준합니다. ")]), _c('li', [_c('span', {
    staticClass: "txt_num"
  }, [_vm._v("③")]), _vm._v("회사는 회원이 서비스 제공으로부터 기대되는 이익을 얻지 못하였거나, 서비스에 대한 취사선택 또는 이용으로 발생하는 손해 등에 대해서는 책임을 지지 않습니다. ")]), _c('li', [_c('span', {
    staticClass: "txt_num"
  }, [_vm._v("④")]), _vm._v("회사는 회원이 서비스 이용 시 제공받을 수 있는 모든 자료들의 진위, 신뢰도, 정확성 등 그 내용에 대해서는 책임지지 않습니다. ")]), _c('li', [_c('span', {
    staticClass: "txt_num"
  }, [_vm._v("⑤")]), _vm._v("회사는 회원의 귀책사유로 인한 서비스 이용의 장애에 대하여는 회사의 귀책사유가 없는 한 책임을 지지 않습니다. ")]), _c('li', [_c('span', {
    staticClass: "txt_num"
  }, [_vm._v("⑥")]), _vm._v("회사는 회원 상호간 또는 회원과 제3자간에 발생한 분쟁 등에 대하여 회사의 귀책사유가 없는 한 책임을 지지 않습니다. ")])])]), _c('div', {
    staticClass: "terms_notice_date"
  }, [_c('p', [_vm._v("[부칙] 1. 이 약관은 2022.09.27일 부터 적용됩니다.")])])])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }