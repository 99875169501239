var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c(_vm.tagName, {
    tag: "component",
    domProps: {
      "innerHTML": _vm._s(_vm.description)
    }
  });
}
var staticRenderFns = []

export { render, staticRenderFns }