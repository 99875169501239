var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('fragment', _vm._l(_vm.list, function (option, index) {
    return _c('div', {
      key: index,
      staticClass: "inp_radio"
    }, [_c('label', [_c('input', {
      attrs: {
        "type": "radio",
        "name": _vm.groupName,
        "disabled": _vm.isDisabledOption(option)
      },
      domProps: {
        "value": option.code || option,
        "checked": _vm.isCheckedOption(option)
      },
      on: {
        "change": function change($event) {
          return _vm.changeHandler(option);
        }
      }
    }), _c('span', {
      staticClass: "inp_text"
    }, [_vm._v(" " + _vm._s(option.text || option))])])]);
  }), 0);
}
var staticRenderFns = []

export { render, staticRenderFns }