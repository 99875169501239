var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm._m(0);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "loading_ui"
  }, [_c('div', {
    staticClass: "loading_inwrap"
  }, [_c('div', {
    staticClass: "loading_icon"
  }, [_c('i', {
    staticClass: "ico_item"
  })])])]);
}]

export { render, staticRenderFns }