var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('button', {
    class: _vm.classNames,
    attrs: {
      "disabled": _vm.disabled
    },
    on: {
      "click": _vm.clickHandler
    }
  }, [_vm.icon ? _c('i', {
    class: _vm.icon
  }) : _vm._e(), _vm.$slots.default ? _c('span', [_vm._t("default")], 2) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }