var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.isInitialized ? _c('RouterTabLayout', {
    attrs: {
      "tab-list": _vm.tabList,
      "container-class": "sub_wrap",
      "sub-sction-class": _vm.subSctionClass
    }
  }, [_c('div', {
    staticClass: "page_banner_wrap"
  }, [_c('strong', {
    staticClass: "page_banner_title",
    domProps: {
      "innerHTML": _vm._s(_vm.bannerTitle)
    }
  })]), _c('router-view')], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }