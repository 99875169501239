var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "swiper_slide_wrap",
    class: _vm.className
  }, [_c('div', {
    ref: "containerRef",
    staticClass: "swiper",
    class: _vm.containerClassName
  }, [_c('div', {
    staticClass: "swiper-wrapper"
  }, [_vm._t("default")], 2)]), _vm.usePagination ? _c('div', {
    ref: "paginationRef",
    staticClass: "swiper_pagination"
  }) : _vm._e(), _vm.useNavigation ? _c('div', {
    staticClass: "swiper_button_wrap"
  }, [_c('button', {
    ref: "prevButtonRef",
    staticClass: "swiper_button_item swiper_button_prev",
    attrs: {
      "type": "button"
    }
  }, [_c('span', {
    staticClass: "blind"
  }, [_vm._v("이전")])]), _c('button', {
    ref: "nextButtonRef",
    staticClass: "swiper_button_item swiper_button_next",
    attrs: {
      "type": "button"
    }
  }, [_c('span', {
    staticClass: "blind"
  }, [_vm._v("다음")])])]) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }