var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "page_title_wrap"
  }, [_c(_vm.tagName, {
    tag: "component",
    staticClass: "page_title_text"
  }, [_vm._t("default")], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }