var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', _vm._g({
    ref: "dropdownRef",
    staticClass: "drop_list_wrap",
    class: {
      'is-selected': _vm.isOpen
    }
  }, _vm.menuHandlers), [_c('div', {
    staticClass: "drop_list_button"
  }, [_c('button', {
    staticClass: "btn btn_drop",
    attrs: {
      "type": "button"
    }
  }, [_c('span', {
    staticClass: "btn_text"
  }, [_vm._v(_vm._s(_vm.title))])])]), _c('div', {
    staticClass: "drop_list_list"
  }, [_c('ul', {
    staticClass: "drop_list_ul"
  }, _vm._l(_vm.list, function (item, index) {
    return _c('li', {
      key: index,
      staticClass: "drop_list_item",
      on: {
        "click": function click($event) {
          $event.preventDefault();
          $event.stopPropagation();
          return _vm.clickHandler(item);
        }
      }
    }, [_c('a', {
      staticClass: "drop_list_link",
      attrs: {
        "href": "#none"
      }
    }, [_c('span', {
      staticClass: "drop_list_text"
    }, [_vm._v(_vm._s(item.text || item))])])]);
  }), 0)])]);
}
var staticRenderFns = []

export { render, staticRenderFns }