var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('header', {
    staticClass: "header_wrap header_wrap_eng"
  }, [_c('div', {
    staticClass: "header_inner"
  }, [_vm._m(0), _vm._m(1), _c('div', {
    staticClass: "header_util"
  }, [_vm._m(2), _c('div', {
    staticClass: "header_menu"
  }, [_c('ul', {
    staticClass: "header_menu_list"
  }, [_c('li', {
    staticClass: "header_menu_item"
  }, [_c('router-link', {
    staticClass: "btn_lang",
    attrs: {
      "to": {
        name: _vm.ROUTE_NAMES.HOME
      }
    }
  }, [_c('span', {
    staticClass: "btn_text"
  }, [_vm._v("KOR")])])], 1)])])])])]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('a', {
    staticClass: "header_logo",
    attrs: {
      "href": ""
    }
  }, [_c('h1', [_c('span', {
    staticClass: "blind"
  }, [_vm._v("WISDOM HOUSE")])])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('nav', {
    staticClass: "header_gnb",
    attrs: {
      "data-ui-gnb": "@@activeMenu"
    }
  }, [_c('div', {
    staticClass: "gnb_wrap",
    attrs: {
      "id": "gnbWrap"
    }
  }, [_c('h2', {
    staticClass: "blind"
  }, [_vm._v("주메뉴")]), _c('ul', {
    staticClass: "gnb_list"
  }, [_c('li', {
    staticClass: "gnb_item"
  }, [_c('a', {
    staticClass: "gnb_menu",
    attrs: {
      "href": "#about"
    }
  }, [_c('span', {
    staticClass: "gnb_menu_txt"
  }, [_vm._v("About Wisdom House")])]), _c('div', {
    staticClass: "gnb_depth"
  })]), _c('li', {
    staticClass: "gnb_item"
  }, [_c('a', {
    staticClass: "gnb_menu",
    attrs: {
      "href": "#book"
    }
  }, [_c('span', {
    staticClass: "gnb_menu_txt"
  }, [_vm._v("Popular")])]), _c('div', {
    staticClass: "gnb_detail"
  }, [_c('ul', {
    staticClass: "gnb_detail_list"
  }, [_c('li', {
    staticClass: "gnb_detail_item"
  }, [_c('a', {
    staticClass: "gnb_detail_menu",
    attrs: {
      "href": "#book"
    }
  }, [_c('span', {
    staticClass: "gnb_detail_txt"
  }, [_vm._v("Books")])])]), _c('li', {
    staticClass: "gnb_detail_item"
  }, [_c('a', {
    staticClass: "gnb_detail_menu",
    attrs: {
      "href": "#kids"
    }
  }, [_c('span', {
    staticClass: "gnb_detail_txt"
  }, [_vm._v("Kids")])])]), _c('li', {
    staticClass: "gnb_detail_item"
  }, [_c('a', {
    staticClass: "gnb_detail_menu",
    attrs: {
      "href": "#webcomic"
    }
  }, [_c('span', {
    staticClass: "gnb_detail_txt"
  }, [_vm._v("Webcomic")])])]), _c('li', {
    staticClass: "gnb_detail_item"
  }, [_c('a', {
    staticClass: "gnb_detail_menu",
    attrs: {
      "href": "#webnovel"
    }
  }, [_c('span', {
    staticClass: "gnb_detail_txt"
  }, [_vm._v("Webnovel")])])])])])]), _c('li', {
    staticClass: "gnb_item"
  }, [_c('a', {
    staticClass: "gnb_menu",
    attrs: {
      "href": "#contacts"
    }
  }, [_c('span', {
    staticClass: "gnb_menu_txt"
  }, [_vm._v("Contacts")])]), _c('div', {
    staticClass: "gnb_depth"
  })]), _c('li', {
    staticClass: "gnb_item"
  }, [_c('a', {
    staticClass: "gnb_menu",
    attrs: {
      "href": "#rightGuide"
    }
  }, [_c('span', {
    staticClass: "gnb_menu_txt"
  }, [_vm._v("Rights Guide")])]), _c('div', {
    staticClass: "gnb_depth"
  })])])])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('a', {
    staticClass: "header_symbol",
    attrs: {
      "href": ""
    }
  }, [_c('span', {
    staticClass: "blind"
  }, [_vm._v("심볼")])]);
}]

export { render, staticRenderFns }