var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "chip_menu_wrap"
  }, [_c('ul', {
    staticClass: "chip_menu_list"
  }, _vm._l(_vm.list, function (item, index) {
    return _c('li', {
      key: index,
      staticClass: "chip_menu_item",
      class: {
        'is-active': _vm.activeTypeCode === item.code
      },
      on: {
        "click": function click($event) {
          $event.preventDefault();
          return _vm.clickHandler(item);
        }
      }
    }, [_c('a', {
      staticClass: "chip_menu_link",
      attrs: {
        "href": "#none"
      }
    }, [_c('span', {
      staticClass: "chip_menu_text"
    }, [_vm._v(_vm._s(item.text))])])]);
  }), 0)]);
}
var staticRenderFns = []

export { render, staticRenderFns }