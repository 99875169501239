var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "inp_set type_calendar"
  }, [_c('div', {
    staticClass: "inp_wrap"
  }, [_c('div', {
    staticClass: "inp_box",
    class: {
      'is-active': _vm.isActive
    }
  }, [_c('input', {
    ref: "targetRef",
    staticClass: "inp_element",
    attrs: {
      "id": _vm.inputId,
      "type": "text",
      "aria-label": "Date-Time",
      "placeholder": _vm.placeholder,
      "title": _vm.inputTitle,
      "readonly": ""
    }
  })])]), _c('label', {
    staticClass: "btn_datepicker",
    attrs: {
      "for": _vm.inputId
    }
  }, [_c('span', {
    staticClass: "blind"
  }, [_vm._v("캘린더에서 날짜 선택하기")]), _c('div', {
    ref: "containerRef",
    staticClass: "datepicker_wrapper",
    attrs: {
      "id": "wrapper"
    }
  })]), _vm._t("append")], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }