var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('ul', {
    class: "gnb".concat(_vm.className, "_list")
  }, _vm._l(_vm.list, function (item, index) {
    return _c('GnbListItem', {
      key: index,
      attrs: {
        "item": item,
        "type": _vm.type,
        "active-list": _vm.activeList
      }
    });
  }), 1);
}
var staticRenderFns = []

export { render, staticRenderFns }