<template>
  <div id="app" class="wrap">
    <template v-if="initDataLoaded">
      <router-view />
    </template>
    <WConfirmManager />
    <WAlertManager />
    <WLoading v-if="isLoading" />
  </div>
</template>
<script>
// import { useRouter } from '@u3u/vue-hooks';
import { useRouter } from '@u3u/vue-hooks';
import { computed, defineComponent, onBeforeMount, watch } from '@vue/composition-api';

import { WAlertManager, WConfirmManager } from '@/components/base';
import { WLoading } from '@/components/base';
import { useGlobalLoader } from '@/composables';
import { useVisibility } from '@/composables';
import logger from '@/modules/logger';
import { userStore } from '@/store';
import { commonCodeStore } from '@/store';

export default defineComponent({
  components: {
    WAlertManager,
    WConfirmManager,
    WLoading,
  },
  setup() {
    // !TODO 브라우저 탭 활성화시(visibilityChange) 회원 정보 동기화 처리 - 1차 스펙 제거 대상
    // const { router } = useRouter();
    // const { visibility } = useVisibility();
    const { isLoading } = useGlobalLoader();

    const initDataLoaded = computed(() => {
      return commonCodeStore.isLoaded;
    });

    const fetchInitData = async () => {
      try {
        await commonCodeStore.fetchCommonCode();
      } catch (e) {
        logger.log('alpha', '공통 코드 요청에 실패하였습니다.', e);
      }
    };

    onBeforeMount(() => {
      fetchInitData();
    });

    // !TODO 브라우저 탭 활성화시(visibilityChange) 회원 정보 동기화 처리 - 1차 스펙 제거 대상
    // watch(
    //   () => visibility.value,
    //   async () => {
    //     if (visibility.value === 'visible') {
    //       const isChanged = await userStore.checkChangedUser();
    //       if (isChanged) {
    //         router.go(0);
    //       }
    //     }
    //   },
    // );

    return {
      initDataLoaded,
      isLoading,
      userStore,
    };
  },
});
</script>
