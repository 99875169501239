var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('WDialog', {
    attrs: {
      "value": _vm.value
    },
    on: {
      "close": _vm.closedHandler,
      "click-outside": _vm.closedHandler
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('h1', {
          staticClass: "layer_header_title"
        }, [_vm._v(_vm._s(_vm.title))])];
      },
      proxy: true
    }])
  }, [_vm._t("default")], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }