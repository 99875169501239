var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('ul', {
    staticClass: "inp_list_wrap_sqr type4"
  }, _vm._l(_vm.list, function (option, index) {
    return _c('li', {
      key: index,
      staticClass: "inp_list_item"
    }, [_c('div', {
      staticClass: "inp_check_sqr"
    }, [_c('label', [_c('input', {
      attrs: {
        "type": "radio",
        "name": _vm.groupName
      },
      domProps: {
        "value": option.code || option,
        "checked": _vm.isCheckedOption(option)
      },
      on: {
        "change": function change($event) {
          return _vm.changeHandler(option);
        }
      }
    }), _c('span', {
      staticClass: "inp_text"
    }, [_c('span', {
      staticClass: "checked_arr"
    }, [_vm._v(_vm._s(option.text || option))])])])])]);
  }), 0);
}
var staticRenderFns = []

export { render, staticRenderFns }