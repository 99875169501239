var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('header', {
    staticClass: "header_wrap",
    class: {
      type2: _vm.isType2Class
    }
  }, [_c('div', {
    staticClass: "header_inner"
  }, [_c('router-link', {
    staticClass: "header_logo",
    attrs: {
      "to": {
        name: _vm.ROUTE_NAMES.HOME
      }
    }
  }, [_c('h1', [_c('span', {
    staticClass: "blind"
  }, [_vm._v("WISDOM HOUSE")])])]), _c('GlobalNavigationBar'), _c('div', {
    staticClass: "header_util"
  }, [_c('router-link', {
    staticClass: "header_symbol",
    attrs: {
      "to": {
        name: _vm.ROUTE_NAMES.HOME
      }
    }
  }, [_c('span', {
    staticClass: "blind"
  }, [_vm._v("심볼")])]), _c('div', {
    staticClass: "header_serach"
  }, [_c('h2', {
    staticClass: "blind"
  }, [_vm._v("통합검색")]), _c('div', {
    staticClass: "inp_wrap type_search"
  }, [_c('div', {
    staticClass: "inp_box"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.searchInputValue,
      expression: "searchInputValue"
    }],
    ref: "searchInputRef",
    staticClass: "inp_element",
    attrs: {
      "type": "search",
      "title": "검색할 검색어를 입력하세요.",
      "maxlength": "30",
      "autocomplete": "off",
      "name": "q"
    },
    domProps: {
      "value": _vm.searchInputValue
    },
    on: {
      "keypress": function keypress($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        return _vm.searchInputKeydownHandler.apply(null, arguments);
      },
      "input": function input($event) {
        if ($event.target.composing) { return; }
        _vm.searchInputValue = $event.target.value;
      }
    }
  }), _c('button', {
    ref: "searchButtonRef",
    staticClass: "btn_search",
    attrs: {
      "type": "button"
    },
    on: {
      "click": _vm.searchButtonClickHandler
    }
  }, [_c('span', {
    staticClass: "blind"
  }, [_vm._v("검색")])])])])]), _c('div', {
    staticClass: "header_menu"
  }, [_c('ul', {
    staticClass: "header_menu_list"
  }, [_vm.isLogin ? [_c('li', {
    staticClass: "header_menu_item mypage_nav"
  }, [_c('router-link', {
    staticClass: "btn_mypage",
    attrs: {
      "to": {
        name: _vm.ROUTE_NAMES.MY_PAGE_MAIN
      }
    }
  }, [_c('span', {
    staticClass: "btn_text"
  }, [_c('em', {
    staticClass: "login_user"
  }, [_vm._v(_vm._s(_vm.userName))]), _c('em', [_vm._v("님")])])]), _c('div', {
    staticClass: "nav"
  }, [_c('ul', [_c('li', [_c('router-link', {
    attrs: {
      "to": {
        name: _vm.ROUTE_NAMES.MY_PAGE_MAIN
      }
    }
  }, [_vm._v("마이페이지")])], 1), _vm.isEmailJoined ? _c('li', [_c('router-link', {
    attrs: {
      "to": {
        name: _vm.ROUTE_NAMES.MY_PAGE_EDIT_INFO
      }
    }
  }, [_vm._v("회원정보 관리")])], 1) : _c('li', [_c('router-link', {
    attrs: {
      "to": {
        name: _vm.ROUTE_NAMES.MY_PAGE_EDIT_SNS_CERTIFICATION
      }
    }
  }, [_vm._v(" 회원정보 관리 ")])], 1), _vm.isEmailJoined ? _c('li', [_c('router-link', {
    attrs: {
      "to": {
        name: _vm.ROUTE_NAMES.MY_PAGE_EDIT_CERTIFICATION
      }
    }
  }, [_vm._v("개인정보 관리")])], 1) : _vm._e(), _c('li', [_c('router-link', {
    attrs: {
      "to": {
        name: _vm.ROUTE_NAMES.MY_PAGE_SERVICE
      }
    }
  }, [_vm._v("서비스 이용현황")])], 1), _c('li', [_c('router-link', {
    attrs: {
      "to": {
        name: _vm.ROUTE_NAMES.SUPPORT_INQUIRY,
        params: {
          useBreadcrumb: false
        }
      }
    }
  }, [_vm._v("문의하기")])], 1)]), _c('div', {
    staticClass: "logout"
  }, [_c('a', {
    attrs: {
      "href": "#none"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.logoutClickHandler.apply(null, arguments);
      }
    }
  }, [_vm._v("로그아웃")])])])], 1)] : [_c('li', {
    staticClass: "header_menu_item"
  }, [_c('router-link', {
    staticClass: "btn_login",
    attrs: {
      "to": {
        name: _vm.ROUTE_NAMES.LOGIN
      }
    }
  }, [_c('span', {
    staticClass: "btn_text"
  }, [_vm._v("LOGIN")])])], 1), _c('li', {
    staticClass: "header_menu_item"
  }, [_c('router-link', {
    staticClass: "btn_join",
    attrs: {
      "to": {
        name: _vm.ROUTE_NAMES.ENG_HOME
      }
    }
  }, [_c('span', {
    staticClass: "btn_text"
  }, [_vm._v("ENG")])])], 1), _c('li')]], 2)])], 1)], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }