var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.isUlTag ? _c('fragment', [_c('ul', {
    staticClass: "inp_list_wrap",
    class: _vm.ulTypeClass
  }, _vm._l(_vm.list, function (option, index) {
    var _domProps, _domProps2;

    return _c('li', {
      key: index,
      staticClass: "inp_list_item",
      class: {
        small: _vm.isSmall
      }
    }, [option.useSlot ? _c('div', {
      staticClass: "inp_group type_inp"
    }, [_c('div', {
      staticClass: "inp_check"
    }, [_c('label', [_c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: _vm.model,
        expression: "model"
      }],
      attrs: {
        "type": "checkbox",
        "name": _vm.groupName,
        "disabled": _vm.isDisabledOption(option)
      },
      domProps: (_domProps = {
        "value": option.code || option,
        "checked": option.checked
      }, _domProps["checked"] = Array.isArray(_vm.model) ? _vm._i(_vm.model, option.code || option) > -1 : _vm.model, _domProps),
      on: {
        "change": function change($event) {
          var $$a = _vm.model,
              $$el = $event.target,
              $$c = $$el.checked ? true : false;

          if (Array.isArray($$a)) {
            var $$v = option.code || option,
                $$i = _vm._i($$a, $$v);

            if ($$el.checked) {
              $$i < 0 && (_vm.model = $$a.concat([$$v]));
            } else {
              $$i > -1 && (_vm.model = $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
            }
          } else {
            _vm.model = $$c;
          }
        }
      }
    }), _c('span', {
      staticClass: "inp_text"
    }, [_vm._v(" " + _vm._s(option.text || option))])])]), option.useSlot ? _vm._t("append") : _vm._e()], 2) : _c('div', {
      staticClass: "inp_check"
    }, [_c('label', [_c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: _vm.model,
        expression: "model"
      }],
      attrs: {
        "type": "checkbox",
        "name": _vm.groupName,
        "disabled": _vm.isDisabledOption(option)
      },
      domProps: (_domProps2 = {
        "value": option.code || option,
        "checked": option.checked
      }, _domProps2["checked"] = Array.isArray(_vm.model) ? _vm._i(_vm.model, option.code || option) > -1 : _vm.model, _domProps2),
      on: {
        "change": function change($event) {
          var $$a = _vm.model,
              $$el = $event.target,
              $$c = $$el.checked ? true : false;

          if (Array.isArray($$a)) {
            var $$v = option.code || option,
                $$i = _vm._i($$a, $$v);

            if ($$el.checked) {
              $$i < 0 && (_vm.model = $$a.concat([$$v]));
            } else {
              $$i > -1 && (_vm.model = $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
            }
          } else {
            _vm.model = $$c;
          }
        }
      }
    }), _c('span', {
      staticClass: "inp_text"
    }, [_vm._v(" " + _vm._s(option.text || option))])])])]);
  }), 0)]) : _c('fragment', _vm._l(_vm.list, function (option, index) {
    var _domProps3;

    return _c('div', {
      key: index,
      staticClass: "inp_check"
    }, [_c('label', [_c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: _vm.model,
        expression: "model"
      }],
      attrs: {
        "type": "checkbox",
        "name": _vm.groupName,
        "disabled": _vm.isDisabledOption(option)
      },
      domProps: (_domProps3 = {
        "value": option.code || option,
        "checked": option.checked
      }, _domProps3["checked"] = Array.isArray(_vm.model) ? _vm._i(_vm.model, option.code || option) > -1 : _vm.model, _domProps3),
      on: {
        "change": function change($event) {
          var $$a = _vm.model,
              $$el = $event.target,
              $$c = $$el.checked ? true : false;

          if (Array.isArray($$a)) {
            var $$v = option.code || option,
                $$i = _vm._i($$a, $$v);

            if ($$el.checked) {
              $$i < 0 && (_vm.model = $$a.concat([$$v]));
            } else {
              $$i > -1 && (_vm.model = $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
            }
          } else {
            _vm.model = $$c;
          }
        }
      }
    }), _c('span', {
      staticClass: "inp_text"
    }, [_vm._v(" " + _vm._s(option.text || option))])])]);
  }), 0);
}
var staticRenderFns = []

export { render, staticRenderFns }