var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('nav', {
    staticClass: "header_gnb",
    on: {
      "mouseenter": _vm.mouseenter,
      "mouseleave": _vm.mouseleave
    }
  }, [_c('div', {
    staticClass: "gnb_wrap"
  }, [_c('h2', {
    staticClass: "blind"
  }, [_vm._v("주메뉴")]), _c('GnbList', {
    attrs: {
      "type": "_menu",
      "list": _vm.GNB_MENU_DATA,
      "active-list": _vm.activeList
    }
  })], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }