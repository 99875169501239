var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('FooterDialogWrapper', _vm._g({
    attrs: {
      "value": _vm.value,
      "title": "위즈덤하우스 개인정보처리방침"
    }
  }, _vm.$listeners), [_c('div', {
    staticClass: "layer_content"
  }, [_c('section', {
    staticClass: "layer_content_inner layer_util_terms"
  }, [_c('p', [_vm._v(" 위즈덤하우스 주식회사(이하 ‘위즈덤하우스’ 또는 ‘회사’)는 서비스 기획부터 종료까지 개인정보보호법 등 국내의 개인정보 보호 법령을 철저히 준수합니다. ")]), _c('p', [_vm._v("본 개인정보처리방침의 목차는 아래와 같습니다.")]), _c('p', [_vm._v(" 관계법령이 요구하는 개인정보처리방침의 필수 사항과 위즈덤하우스 자체적으로 이용자 개인정보 보호에 있어 중요하게 판단하는 내용을 포함하였습니다. ")]), _c('ul', {
    staticClass: "txt_list type_num"
  }, [_c('li', [_c('span', {
    staticClass: "txt_num"
  }, [_vm._v("1.")]), _vm._v("수집하는 개인정보")]), _c('li', [_c('span', {
    staticClass: "txt_num"
  }, [_vm._v("2.")]), _vm._v("수집한 개인정보의 이용")]), _c('li', [_c('span', {
    staticClass: "txt_num"
  }, [_vm._v("3.")]), _vm._v("개인정보의 제공 및 위탁")]), _c('li', [_c('span', {
    staticClass: "txt_num"
  }, [_vm._v("4.")]), _vm._v("개인정보의 파기")]), _c('li', [_c('span', {
    staticClass: "txt_num"
  }, [_vm._v("5.")]), _vm._v("휴면계정 안내")]), _c('li', [_c('span', {
    staticClass: "txt_num"
  }, [_vm._v("6.")]), _vm._v("이용자 및 법정대리인의 권리와 행사 방법")]), _c('li', [_c('span', {
    staticClass: "txt_num"
  }, [_vm._v("7.")]), _vm._v("본 개인정보처리방침의 적용 범위")]), _c('li', [_c('span', {
    staticClass: "txt_num"
  }, [_vm._v("8.")]), _vm._v("개정 전 고지 의무")])]), _c('h2', {
    staticClass: "terms_title"
  }, [_vm._v("1. 수집하는 개인정보")]), _c('div', {
    staticClass: "terms_paragraph_box"
  }, [_c('p', [_vm._v(" 이용자는 회원가입을 하지 않아도 도서 정보, 저자 정보 보기 등 대부분의 위즈덤하우스 서비스를 회원과 동일하게 이용할 수 있습니다. ")]), _c('p', [_vm._v(" 이용자가 강연, 북클럽, 도서관 지원 서비스 등과 같이 신청, 결제 혹은 회원제 서비스 등을 이용하기 위해 회원가입을 할 경우, 위즈덤하우스는 서비스 이용을 위해 필요한 최소한의 개인정보를 수집합니다. ")]), _c('ul', {
    staticClass: "txt_list type_num2"
  }, [_c('li', [_c('span', {
    staticClass: "txt_num"
  }, [_vm._v("1)")]), _vm._v("회원가입 시점에 위즈덤하우스가 이용자로부터 수집하는 개인정보는 아래와 같습니다. "), _c('ul', {
    staticClass: "txt_list type_dash"
  }, [_c('li', [_vm._v(" 회원가입 시에 '이메일, 비밀번호, 이름'을 필수항목으로 수집합니다. 주민번호를 받지 않으며 본인인증 서비스를 실시합니다. 본인인증 시 '이름, 핸드폰번호, 생년월일'을 수집하며 카카오 인증서의 경우 CI(연계정보)를 수집합니다. 생년월일이 만 14세 미만일 경우에는 회원가입이 불가합니다. ")])])]), _c('li', [_c('span', {
    staticClass: "txt_num"
  }, [_vm._v("2)")]), _vm._v("서비스 이용 과정에서 이용자로부터 수집하는 개인정보는 아래와 같습니다. "), _c('ul', {
    staticClass: "txt_list type_dash"
  }, [_c('li', [_vm._v(" 위즈덤하우스 내의 개별 서비스 이용, 이벤트 응모 및 신청 과정에서 해당 서비스의 이용자에 한해 추가 개인정보 수집이 발생할 수 있습니다. 추가로 개인정보를 수집할 경우에는 해당 개인정보 수집 시점에 이용자에게 안내 드리고 동의를 받습니다. ")]), _c('li', [_vm._v(" 이용자 동의 후 개인정보를 추가 수집하는 경우 "), _c('div', {
    staticClass: "tbl_wrap"
  }, [_c('table', [_c('caption', [_vm._v(" 서비스명/수집하는 개인정보 항목 ")]), _c('colgroup', [_c('col', {
    staticStyle: {
      "width": "50%"
    }
  }), _c('col', {
    staticStyle: {
      "width": "50%"
    }
  })]), _c('thead', [_c('tr', [_c('th', {
    attrs: {
      "scope": "col"
    }
  }, [_vm._v("서비스명")]), _c('th', {
    attrs: {
      "scope": "col"
    }
  }, [_vm._v("수집하는 개인정보 항목")])])]), _c('tbody', [_c('tr', [_c('td', [_vm._v("원고 투고")]), _c('td', [_vm._v("이름, 핸드폰 번호, 이메일")])]), _c('tr', [_c('td', [_vm._v("고객/제휴문의")]), _c('td', [_vm._v("이름, 핸드폰 번호, 이메일, 주소, 기관명/회사명")])]), _c('tr', [_c('td', [_vm._v("강연/북클럽/이벤트")]), _c('td', [_vm._v("이름, 핸드폰 번호, 이메일")])]), _c('tr', [_c('td', [_vm._v("워크숍/공모전")]), _c('td', [_vm._v("이름, 핸드폰 번호, 이메일, 주소")])]), _c('tr', [_c('td', [_vm._v("원화 전시/작가와의 만남")]), _c('td', [_vm._v("기관명, 기관 전화번호, 기관 주소, 담당자명, 담당자 핸드폰 번호, 담당자 이메일")])]), _c('tr', [_c('td', [_vm._v("독서 활동 자료실")]), _c('td', [_vm._v("이름, 핸드폰 번호, 주소")])]), _c('tr', [_c('td', [_vm._v("뉴스레터")]), _c('td', [_vm._v("이름, 이메일")])])])])])])])]), _c('li', [_c('span', {
    staticClass: "txt_num"
  }, [_vm._v("3)")]), _vm._v("위즈덤하우스는 아래의 방법을 통해 개인정보를 수집합니다. "), _c('ul', {
    staticClass: "txt_list type_dash"
  }, [_c('li', [_vm._v(" 회원가입 및 서비스 이용 과정에서 이용자가 개인정보 수집에 대해 동의를 하고 직접 정보를 입력하는 경우, 해당 개인정보를 수집합니다. ")]), _c('li', [_vm._v(" 고객지원을 통한 상담 과정에서 웹페이지, 메일, 팩스, 전화 등을 통해 이용자의 개인정보가 수집될 수 있습니다. ")]), _c('li', [_vm._v("오프라인에서 진행되는 이벤트, 세미나 등에서 서면을 통해 개인정보가 수집될 수 있습니다.")]), _c('li', [_vm._v(" 온/오프라인 서점 혹은 외부 기관을 통해 개인정보를 제공받을 수 있으며, 이러한 경우에는 개인정보보호법에 따라 제휴사에서 이용자에게 개인정보 제공 동의 등을 받은 후에 위즈덤하우스에 제공합니다. ")]), _c('li', [_vm._v(" 기기 정보와 같은 생성 정보는 PC웹, 모바일 웹/앱 이용 과정에서 자동으로 생성되어 수집될 수 있습니다. ")])])])])]), _c('h2', {
    staticClass: "terms_title"
  }, [_vm._v("2. 수집한 개인정보의 이용")]), _c('div', {
    staticClass: "terms_paragraph_box"
  }, [_c('p', [_vm._v(" 위즈덤하우스 및 위즈덤하우스 관련 제반 서비스(모바일 웹/앱 포함)의 회원관리, 서비스 개발·제공 및 향상, 안전한 인터넷 이용환경 구축 등 아래의 목적으로만 개인정보를 이용합니다. ")]), _c('ul', {
    staticClass: "txt_list type_dash"
  }, [_c('li', [_vm._v(" 회원 가입 의사의 확인, 연령 확인 및 법정대리인 동의 진행, 이용자 및 법정대리인의 본인 확인, 이용자 식별, 회원탈퇴 의사의 확인 등 회원관리를 위하여 개인정보를 이용합니다. ")]), _c('li', [_vm._v(" 콘텐츠 등 기존 서비스 제공(광고 포함)에 더하여, 인구통계학적 분석, 서비스 방문 및 이용 기록의 분석, 개인정보 및 관심에 기반한 이용자 분석 등 신규 서비스 요소의 발굴 및 기존 서비스 개선 등을 위하여 개인정보를 이용합니다. ")]), _c('li', [_vm._v(" 법령 및 위즈덤하우스 이용약관을 위반하는 회원에 대한 이용 제한 조치, 저작권을 위반하는 회원에 대한 이용 제한 조치, 부정 이용 행위를 포함하여 서비스의 원활한 운영에 지장을 주는 행위에 대한 방지 및 제재, 계정 도용 및 부정거래 방지, 약관 개정 등의 고지사항 전달, 분쟁조정을 위한 기록 보존, 민원처리 등 이용자 보호 및 서비스 운영을 위하여 개인정보를 이용합니다. ")]), _c('li', [_vm._v(" 유료 서비스 제공에 따르는 본인인증, 구매 및 요금 결제, 상품 및 서비스의 제공을 위하여 개인정보를 이용합니다. ")]), _c('li', [_vm._v(" 이벤트 정보 및 참여기회 제공, 광고성 정보 제공 등 마케팅 및 프로모션 목적으로 개인정보를 이용합니다. ")]), _c('li', [_vm._v(" 서비스 이용 기록과 접속 빈도 분석, 서비스 이용에 대한 통계, 서비스 분석 및 통계에 따른 맞춤 서비스 제공 및 광고 게재 등에 개인정보를 이용합니다. ")]), _c('li', [_vm._v(" 보안, 프라이버시, 안전 측면에서 이용자가 안심하고 이용할 수 있는 서비스 이용환경 구축을 위해 개인정보를 이용합니다. ")])])]), _c('h2', {
    staticClass: "terms_title"
  }, [_vm._v("3. 개인정보의 제공 및 위탁")]), _c('div', {
    staticClass: "terms_paragraph_box"
  }, [_c('p', [_vm._v(" 위즈덤하우스는 원칙적으로 이용자 동의 없이 개인정보를 외부에 제공하지 않습니다. 단, 이용자가 외부 제휴사의 서비스를 이용하기 위하여 개인정보 제공에 직접 동의를 한 경우, 그리고 관련 법령에 의거해 위즈덤하우스에 개인정보 제출 의무가 발생한 경우에 한하여 개인정보를 제공하고 있습니다. ")]), _c('ul', {
    staticClass: "txt_list type_dash"
  }, [_c('li', [_vm._v(" 위즈덤하우스가 개인정보처리 업무를 위탁하는 경우 "), _c('div', {
    staticClass: "tbl_wrap"
  }, [_c('table', [_c('caption', [_vm._v(" 위즈덤하우스 - 개인정보처리 업무 위탁 경우 안내 ")]), _c('colgroup', [_c('col', {
    staticStyle: {
      "width": "34%"
    }
  }), _c('col', {
    staticStyle: {
      "width": "35%"
    }
  }), _c('col', {
    staticStyle: {
      "width": "31%"
    }
  })]), _c('thead', [_c('tr', [_c('th', {
    attrs: {
      "scope": "col"
    }
  }, [_vm._v("개인정보 처리 업무를"), _c('br'), _vm._v("위탁받는 자")]), _c('th', {
    attrs: {
      "scope": "col"
    }
  }, [_vm._v("위탁 업무 내용")]), _c('th', {
    attrs: {
      "scope": "col"
    }
  }, [_vm._v("위탁 기간")])])]), _c('tbody', [_c('tr', [_c('td', [_vm._v("엔에이치엔클라우드㈜")]), _c('td', [_vm._v("서비스 제공을 위한 시스템 개발 및 운영, 데이터 보관")]), _c('td', {
    attrs: {
      "rowspan": "5"
    }
  }, [_vm._v(" 위탁 계약 종료 시 또는 "), _c('br'), _vm._v("회원 탈퇴, 개인정보 이용동의 "), _c('br'), _vm._v("철회 요청 시까지 ")])]), _c('tr', [_c('td', [_vm._v("롯데글로벌로지스"), _c('br'), _vm._v("우체국")]), _c('td', [_vm._v("도서 및 물품 배송")])]), _c('tr', [_c('td', [_vm._v("케이지이니시스㈜"), _c('br'), _vm._v("코라아포트원(아임포트)")]), _c('td', [_vm._v("본인인증 절차, 유료 서비스 결제 관리 및 정산")])]), _c('tr', [_c('td', [_vm._v("휴머스온(타스온)"), _c('br'), _vm._v("엔에이치엔클라우드㈜")]), _c('td', [_vm._v("SMS, LMS 전송")])]), _c('tr', [_c('td', [_vm._v("스티비㈜"), _c('br'), _vm._v("엔에이치엔클라우드㈜")]), _c('td', [_vm._v("이메일 전송")])])])])])])])]), _c('h2', {
    staticClass: "terms_title"
  }, [_vm._v("4. 개인정보의 파기")]), _c('div', {
    staticClass: "terms_paragraph_box"
  }, [_c('p', [_vm._v(" 회사는 회원탈퇴, 서비스 종료, 이용자가 개인정보의 이용에 관한 동의를 철회하는 경우, 이용자에게 동의 받은 개인정보 보유기간의 도래와 같이 개인정보의 수집 및 이용목적이 달성된 개인정보는 지체없이 파기하고 있습니다. 출력된 형태의 개인정보는 파쇄 및 소각되며 전자 파일은 복구 불가능한 방법으로 파기합니다. ")]), _c('p', [_vm._v(" 단, 법령에서 일정 기간 정보 보관 의무를 부과하는 경우에는 해당 기간 동안 개인정보를 안전하게 보관하며 법령에서 보존의무를 부과한 정보에 대해서도 해당 기간 경과 후 파기합니다. ")]), _c('p', [_vm._v(" 전자상거래 등에서의 소비자 보호에 관한 법률, 전자문서 및 전자거래 기본법, 통신비밀보호법 등 법령에서 일정기간 정보의 보관을 규정하는 경우는 아래와 같습니다. 위즈덤하우스는 이 기간 동안 법령의 규정에 따라 개인정보를 보관하며, 본 정보를 다른 목적으로는 절대 이용하지 않습니다. ")]), _c('p', [_vm._v("전자상거래 등에서 소비자 보호에 관한 법률에 의거")]), _c('ul', {
    staticClass: "txt_list type_dash"
  }, [_c('li', [_vm._v("계약 또는 청약철회 등에 관한 기록 : 5년 보관")]), _c('li', [_vm._v("대금결제 및 재화 등의 공급에 관한 기록 : 5년 보관")]), _c('li', [_vm._v("소비자의 불만 또는 분쟁처리에 관한 기록 : 3년 보관")])]), _c('p', [_vm._v("통신비밀보호법에 의거")]), _c('ul', {
    staticClass: "txt_list type_dash"
  }, [_c('li', [_vm._v("로그인 기록 : 3개월")])])]), _c('h2', {
    staticClass: "terms_title"
  }, [_vm._v("5. 휴면계정")]), _c('div', {
    staticClass: "terms_paragraph_box"
  }, [_c('p', [_vm._v(" 사용자가 위즈덤하우스의 웹사이트 로그인하지 않은 지 1년이 경과한 경우, 위즈덤하우스는 사용자의 계정 상태를 “휴면계정”으로 전환하고, 사용자의 계정 및 관련 정보를 별도로 보관하고 처리할 수 있습니다. ")]), _c('p', [_vm._v(" 사용자의 계정이 “휴면계정”으로 전환되는 경우, 사용자는 해당 계정을 이용하여 위즈덤하우스의 서비스를 이용할 수 없습니다. 따라서 사용자의 계정을 재활성화하기 위해서는 별도로 제공되는 절차를 따라야 합니다. 사용자의 계정이 “휴면계정”으로 전환되기를 원치 않으시는 경우, 1년이 경과하기 전에 위즈덤하우스의 웹사이트에 로그인하셔야 합니다. ")])]), _c('h2', {
    staticClass: "terms_title"
  }, [_vm._v("6. 이용자 및 법정대리인의 권리와 행사 방법")]), _c('div', {
    staticClass: "terms_paragraph_box"
  }, [_c('ul', {
    staticClass: "txt_list type_dash"
  }, [_c('li', [_vm._v(" 이용자는 언제든지 ‘마이페이지 > 회원정보’에서 자신의 개인정보를 조회하거나 수정할 수 있으며, 자신의 개인정보에 대한 열람을 요청할 수 있습니다. ")]), _c('li', [_vm._v(" 이용자는 언제든지 개인정보 처리의 정지를 요청할 수 있으며, 법률에 특별한 규정이 있는 등의 경우에는 처리정지 요청을 거부할 수 있습니다. ")]), _c('li', [_vm._v("이용자는 언제든지 ‘회원탈퇴’ 등을 통해 개인정보의 수집 및 이용 동의를 철회할 수 있습니다.")])])]), _c('h2', {
    staticClass: "terms_title"
  }, [_vm._v("7. 본 개인정보처리방침의 적용 범위")]), _c('div', {
    staticClass: "terms_paragraph_box"
  }, [_c('p', [_vm._v(" 본 개인정보처리방침은 ‘위즈덤하우스("), _c('a', {
    attrs: {
      "href": "mailto:https://www.wisdomhouse.co.kr",
      "target": "_blank",
      "title": "위즈덤하우스 문의 메일보내기 "
    }
  }, [_vm._v("www.wisdomhouse.co.kr")]), _vm._v(")’ 및 관련 제반 서비스(모바일 웹 포함)에 적용되며, 위즈덤하우스가 제공하는 다른 서비스에 대해서는 별개의 개인정보처리방침이 적용될 수 있습니다. ")])]), _c('h2', {
    staticClass: "terms_title"
  }, [_vm._v("8. 개정 전 고지 의무")]), _c('div', {
    staticClass: "terms_paragraph_box"
  }, [_c('p', [_vm._v(" 본 개인정보처리방침의 내용 추가, 삭제 및 수정이 있을 경우 개정 최소 7일 전에 ‘공지사항’을 통해 사전 공지 합니다. ")]), _c('p', [_vm._v(" 단, 수집하는 개인정보의 항목, 이용목적의 변경 등과 같이 이용자 권리의 중대한 변경이 발생할 때에는 최소 30일 전에 공지하며, 필요 시 이용자 동의를 다시 받을 수도 있습니다. ")])]), _c('div', {
    staticClass: "terms_notice_date"
  }, [_c('ul', {
    staticClass: "terms_date_info"
  }, [_c('li', [_vm._v("공고 일자 : 2023.01.09")]), _c('li', [_vm._v("시행 일자 : 2023.01.09")])])])])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }