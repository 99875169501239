var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "drop_list_group"
  }, [_c('ul', {
    staticClass: "drop_list_inwrap"
  }, _vm._l(_vm.list, function (menu, index) {
    return _c('li', {
      key: index,
      staticClass: "drop_list_menu"
    }, [_c('div', {
      staticClass: "drop_list_wrap",
      class: {
        'is-active': menu.code === _vm.activeMenuCode
      }
    }, [!menu.list || !menu.list.length ? _c('div', {
      staticClass: "drop_list_button"
    }, [_c('button', {
      staticClass: "btn",
      attrs: {
        "type": "button"
      },
      on: {
        "click": function click($event) {
          return _vm.clickHandler(menu.code);
        }
      }
    }, [_c('span', {
      staticClass: "btn_text"
    }, [_vm._v(_vm._s(menu.text))])])]) : _c('WDropdown', {
      attrs: {
        "list": menu.list,
        "title": menu.text
      },
      on: {
        "click": function click(item) {
          return _vm.clickHandler(menu.code, item);
        }
      }
    })], 1)]);
  }), 0)]);
}
var staticRenderFns = []

export { render, staticRenderFns }