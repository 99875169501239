var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "tab_wrap"
  }, [_c('div', {
    staticClass: "tab_menu"
  }, [_c('ul', {
    staticClass: "tab_list"
  }, _vm._l(_vm.list, function (tab, index) {
    return _c('li', {
      key: tab.id,
      staticClass: "tab_item",
      class: {
        'is-active': index === _vm.activeIdx
      }
    }, [_c('a', {
      staticClass: "tab_link",
      attrs: {
        "href": "#none"
      },
      on: {
        "click": function click($event) {
          $event.preventDefault();
          return _vm.clickHandler(tab, index);
        }
      }
    }, [_c('span', {
      staticClass: "tab_text"
    }, [_vm._v(_vm._s(tab.name))])])]);
  }), 0)])]);
}
var staticRenderFns = []

export { render, staticRenderFns }