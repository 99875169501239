var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "container sub_wrap",
    class: _vm.containerClass,
    attrs: {
      "id": "container"
    }
  }, [_c('article', {
    staticClass: "content",
    class: _vm.articleClass,
    attrs: {
      "id": "content"
    }
  }, [_c('div', {
    staticClass: "sub_section_wrap",
    class: _vm.subSctionClass
  }, [_vm.useBreadcrumb ? _c('Breadcrumb') : _vm._e(), _c('TabList', {
    attrs: {
      "list": _vm.tabList,
      "active-index": _vm.activeTabIndex
    },
    on: {
      "tabChange": _vm.tabChangeHandler
    }
  }), _vm._t("default")], 2)])]);
}
var staticRenderFns = []

export { render, staticRenderFns }