var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('footer', {
    staticClass: "footer_wrap"
  }, [_c('div', {
    staticClass: "footer_inner"
  }, [_c('div', {
    staticClass: "footer_top_area"
  }, [_vm._m(0), _c('div', {
    staticClass: "footer_menu_wrap"
  }, [_c('FooterList', {
    attrs: {
      "type": "menu",
      "class-name": 'footer_menu_list',
      "list": _vm.FOOTER_MENU_DATA
    }
  })], 1)]), _c('div', {
    staticClass: "footer_bottom_area"
  }, [_c('div', {
    staticClass: "footer_util_wrap"
  }, [_c('FooterList', {
    attrs: {
      "type": "util",
      "class-name": 'footer_sns_info',
      "list": _vm.FOOTER_UTIL_DATA
    }
  })], 1), _vm._m(1), _c('div', {
    staticClass: "footer_sns_wrap"
  }, [_c('FooterList', {
    attrs: {
      "type": "sns",
      "class-name": 'footer_sns_list',
      "list": _vm.FOOTER_SNS_DATA
    }
  })], 1), _c('cite', {
    staticClass: "footer_copyright"
  }, [_vm._v("Copyright © WISDOMHOUSE Inc, All Rights Reserved.")])])])]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('h1', {
    staticClass: "footer_logo"
  }, [_c('span', {
    staticClass: "blind"
  }, [_vm._v("위즈덤하우스")])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "footer_company_wrap"
  }, [_c('dl', {
    staticClass: "footer_company_list"
  }, [_c('dt', {
    staticClass: "footer_company_title footer_company_name"
  }, [_vm._v("(주) 위즈덤하우스")]), _c('dd', {
    staticClass: "footer_company_item footer_company_ceo"
  }, [_vm._v("대표자 : 최순영")]), _c('dd', {
    staticClass: "footer_company_item footer_company_num"
  }, [_vm._v("사업자등록번호 : 105-87-19009")]), _c('dd', {
    staticClass: "footer_company_item footer_company_address"
  }, [_vm._v(" 주소 : 서울 마포구 양화로 19(합정동, 합정오피스빌딩) ")]), _c('dd', {
    staticClass: "footer_company_item footer_company_tel"
  }, [_vm._v("대표번호 : 02-2179-5600")]), _c('dd', {
    staticClass: "footer_company_item footer_company_fax"
  }, [_vm._v("팩스번호 : 02-6748-3510")]), _c('dd', {
    staticClass: "footer_company_item footer_company_mail"
  }, [_vm._v(" E-mail : wisdomhouse@wisdomhouse.co.kr ")])])]);
}]

export { render, staticRenderFns }