var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('RouterTabLayout', {
    attrs: {
      "tab-list": _vm.tabList,
      "container-class": "sub_wrap",
      "sub-sction-class": _vm.subSectionWrapperClassName
    }
  }, [_c('router-view')], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }