var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('nav', {
    staticClass: "breadcrumb_wrap"
  }, [_c('div', {
    staticClass: "breadcrumb_inner"
  }, [_c('ul', {
    staticClass: "breadcrumb_list"
  }, _vm._l(_vm.list, function (item, index) {
    return _c('li', {
      key: item.name,
      staticClass: "breadcrumb_item",
      class: "breadcrumb_step".concat(index)
    }, [_c('router-link', {
      staticClass: "breadcrumb_link",
      attrs: {
        "to": {
          name: item.name
        }
      }
    }, [_vm._v(" " + _vm._s(item.text) + " ")])], 1);
  }), 0)])]);
}
var staticRenderFns = []

export { render, staticRenderFns }