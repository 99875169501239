var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "page_desc_wrap"
  }, [_vm.lineBreakContents ? _c('LineBreakElement', {
    staticClass: "page_desc_text",
    attrs: {
      "tag-name": "p",
      "contents": _vm.lineBreakContents
    }
  }) : _c('p', {
    staticClass: "page_desc_text"
  }, [_vm._t("default")], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }